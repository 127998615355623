const usStateRPGData = [
  {
    abbr: 'AK',
    name: 'Alaska',
    profiles: 3,
    files: [
      {
        title:
          'Alaska Native Family Preservation (ANFP) Program - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Cook_Inlet_Council_Inc_AK_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title:
          'Team Decision Making Enhancement for Strong Native Families - RPG Round 4: 2017—2022',
        url: '/files/ta-profiles/rpg-4-profile-ak-citc.pdf',
        fileSize: '1%',
        status: 'past',
        round: '4',
      },
      {
        title:
          'Connected & Strong Native Families (CSNF) - RPG Round 7: 2022—2027',
        url: '/files/ta-profiles/rpg-7-profile-ak-citc.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
    ],
  },
  {
    abbr: 'ME',
    name: 'Maine',
    profiles: 1,
    files: [
      {
        title: 'Penquis Regional Linking Project - RPG Round 2: 2012—2017',
        url: '/files/ta-profiles/Site_Profile_FACT_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
    ],
  },
  {
    abbr: 'VT',
    name: 'Vermont',
    profiles: 2,
    files: [
      {
        title: 'Regional Interagency Screening, Assessment, and Treatment Collaboration - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Lund_Family_Center_VT_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title: 'Vermont Family Recovery Project - RPG Round 4: 2017—2022',
        url: '/files/ta-profiles/rpg-4-profile-vt-lund.pdf',
        fileSize: '1%',
        status: 'past',
        round: '4',
      },
    ],
  },
  {
    abbr: 'NH',
    name: 'New Hampshire',
    profiles: 1,
    files: [
      {
        title: 'Partners to Promote Safety, Permanency and Well-Being for Families Affected by Substance Use - RPG Round 6: 2019—2024',
        url: '/files/ta-profiles/rpg-6-profile-nh-hitchcock.pdf',
        fileSize: '1%',
        status: 'current',
        round: '6',
      },
    ],
  },
  {
    abbr: 'WA',
    name: 'Washington',
    profiles: 2,
    files: [
      {
        title: 'Methamphetamine Family Services (MFS) - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Pierce_County_Alliance_WA_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title:
          'Rising Strong Regional Partnership: Family-Centered Treatment with Housing Program - RPG Round 4: 2017—2022',
        url: '/files/ta-profiles/rpg_iv_grantee_profile_wa.pdf',
        fileSize: '1%',
        status: 'past',
        round: '4',
      },
    ],
  },
  {
    abbr: 'MT',
    name: 'Montana',
    profiles: 3,
    files: [
      {
        title: 'Meth Free Crowalition (MFC) - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Apsaalooke_Nation_MT_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title:
          'Family Housing Matters (FHM) - A Partnersing for Progressive Initiative of the Center for Children and Families - RPG Round 1 Extension: 2012—2015',
        url: '/files/ta-profiles/CenterForChildren_NH_Complete_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1 Extension',
      },
      {
        title: 'Family Treatment Matters (FTM) - RPG Round 2: 2012—2017',
        url: '/files/ta-profiles/Site_Profile_Center_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
    ],
  },
  { abbr: 'ND', name: 'North Dakota', profiles: 0, files: [] },
  {
    abbr: 'MN',
    name: 'Minnesota',
    profiles: 1,
    files: [
      {
        title: 'White Earth Band of Ojibwe, Indian Child Welfare Program - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/White_Earth_Band_of_Chippewa_MN_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
    ],
  },
  {
    abbr: 'WI',
    name: 'Wisconsin',
    profiles: 3,
    files: [
      {
        title:
          'Milwaukee Partnership on Well-Being and Recovery (M-POWER) Program - RPG Round 4: 2017—2022',
        url: '/files/ta-profiles/rpg-4-profile-wi-mh.pdf',
        fileSize: '1%',
        status: 'past',
        round: '4',
      },
      {
        title: 'Meta House MKE RPG - RPG Round 7: 2022—2027',
        url: '/files/ta-profiles/rpg-7-profile-wi-meta-house.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
      {
        title:
          'Wisconsin Department of Children and Families - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/WI_DH_and_Family_Services_WI_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
    ],
  },
  { abbr: 'MI', name: 'Michigan', profiles: 0, files: [] },
  {
    abbr: 'NY',
    name: 'New York',
    profiles: 5,
    files: [
      {
        title:
          'A Regional Partnership for New York City to Improve Child Welfare Outcomes Among Pregnant Women at Risk of Substance Misuse and Their Newborns - RPG Round 5: 2018-2023',
        url: '/files/ta-profiles/rpg-5-profile-ny-montefiore.pdf',
        fileSize: '1%',
        status: 'past',
        round: '5',
      },
      {
        title:
          'The Empowering Dads Program: A Regional Partnership for New York City to Improve Well-Being and Child Welfare Outcomes Among Families at Risk of Substance Misuse Through Fatherhood Engagement - RPG Round 7: 2022—2027',
        url: '/files/ta-profiles/rpg-7-profile-ny-montefiore.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
      {
        title:
          'A Regional Partnership for New York City to Improve Child Welfare Outcomes Among Substance Abusing Families - RPG Round 3: 2014—2019',
        url: '/files/ta-profiles/Site_Profile_NY_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '3',
      },
      {
        title:
          'Fostering Recovery: Supporting Young Children Exposed to Substance Abuse and Their Families - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/University_of_Rochester_NY_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title:
          'Westchester County Department of Community Mental Health - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Westchester_County_NY_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
    ],
  },
  {
    abbr: 'MA',
    name: 'Massachusetts',
    profiles: 3,
    files: [
      {
        title:
          'Massachusetts Family Recovery Project - Hampden County - RPG Round 1 Extension: 2012—2015',
        url: '/files/ta-profiles/Mass_MC_Complete_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1 Extension',
      },
      {
        title: 'Family Recovery Project Central - RPG Round 5: 2018-2023',
        url: '/files/ta-profiles/rpg-5-profile-ma-ihr.pdf',
        fileSize: '1%',
        status: 'past',
        round: '5',
      },
      {
        title:
          'Massachusetts Family Recovery Project Southeast - RPG Round 2: 2012—2017',
        url: '/files/ta-profiles/Site_Profile_MA_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
    ],
  },
  {
    abbr: 'RI',
    name: 'Rhode Island',
    profiles: 1,
    files: [
      {
        title: 'Project Connect - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Childrens_Friend_and_Service_RI_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
    ],
  },
  {
    abbr: 'ID',
    name: 'Idaho',
    profiles: 1,
    files: [
      {
        title:
          'Improving Positve Outcomes for Children Through Family Drug Court - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/ID_DH_Welfare_ID_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
    ],
  },
  { abbr: 'WY', name: 'Wyoming', profiles: 0, files: [] },
  {
    abbr: 'SD',
    name: 'South Dakota',
    profiles: 1,
    files: [
      {
        title: 'Volunteers of America, Dakotas Regional Partnership Grant Project - RPG Round 5: 2018-2023',
        url: '/files/ta-profiles/rpg-5-profile-sd-voad.pdf',
        fileSize: '1%',
        status: 'past',
        round: '5',
      },
    ],
  },
  {
    abbr: 'IA',
    name: 'Iowa',
    profiles: 9,
    files: [
      {
        title:
          'Parents and Children Together (PACT) Extension Grant - RPG Round 1 Extension: 2012—2015',
        url: '/files/ta-profiles/Iowa_JP_Complete_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1 Extension',
      },
      {
        title:
          'Seasons Regional Partnership for Trauma-Informed Care (TIC) - RPG Round 4: 2017—2022',
        url: '/files/ta-profiles/rpg_iv_grantee_profile_ia.pdf',
        fileSize: '1%',
        status: 'past',
        round: '4',
      },
      {
        title: 'Judiciary Courts for the State - RPG Round 5: 2018-2023',
        url: '/files/ta-profiles/rpg-5-profile-ia-judiciary.pdf',
        fileSize: '1%',
        status: 'past',
        round: '5',
      },
      {
        title:
          'Seasons Regional Partnership Grant (RPG5) - RPG Round 5: 2018-2023',
        url: '/files/ta-profiles/rpg-5-profile-ia-seasons.pdf',
        fileSize: '1%',
        status: 'past',
        round: '5',
      },
      {
        title:
          'Crittenton Center Regional Partnership Grant - RPG Round 7: 2022—2027',
        url: '/files/ta-profiles/rpg-7-profile-ia-fcc.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
      {
        title:
          'Iowa Children and Family Collaborative: Developing a Telementoring Model to Build Capacity in Rural Communities - RPG Round 7: 2022—2027',
        url: '/files/ta-profiles/rpg-7-profile-ia-judiciary.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
      {
        title:
          'Enhancing Services to Children and Families - RPG Round 2: 2012—2017',
        url: '/files/ta-profiles/Site_Profile_IAJB_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
      {
        title:
          'Seasons-Regional Network for Trauma-Informed Care (TIC) - RPG Round 2: 2012—2017',
        url: '/files/ta-profiles/Site_Profile_IA-Seasons-Center.pdf',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
      {
        title: 'Parent Partners of NW Iowa Program - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Upper_Des_Moines_Opportunity_Inc_IO_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
    ],
  },
  {
    abbr: 'IL',
    name: 'Illinois',
    profiles: 7,
    files: [
      {
        title:
          'Family and Child Treatment Services (FACTS) - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Childrens_Research_Triangle_CRT_IL_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title:
          'Eastern Illinois Regional Partnership Intact Family Recovery Program - RPG Round 6: 2019—2024',
        url: '/files/ta-profiles/rpg-6-profile-il-icoy.pdf',
        fileSize: '1%',
        status: 'current',
        round: '6',
      },
      {
        title:
          'Illinois Intact Family Recovery Program - RPG Round 4: 2017—2022',
        url: '/files/ta-profiles/rpg-4-profile-il-icoy.pdf',
        fileSize: '1%',
        status: 'past',
        round: '4',
      },
      {
        title:
          'Partnerships to Help Children and Families Flourish - RPG Round 5: 2017—2022',
        url: '/files/ta-profiles/rpg-5-profile-il-centerstone.pdf',
        fileSize: '1%',
        status: 'past',
        round: '5',
      },
      {
        title:
          'Centerstone’s Partnership to Help Children and Families Continue to Flourish (2Flourish) - RPG Round 7: 2022—2027',
        url: '/files/ta-profiles/rpg-7-profile-il-centerstone.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
      {
        title:
          'Metro East Regional Partnership – Intact Family Recovery for Families of Color - RPG Round 7: 2022—2027',
        url: '/files/ta-profiles/rpg-7-profile-il-icoy.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
      {
        title: 'Project Thrive: A Partnership to Enhance Child Well-Being, Safety and Permanency - RPG Round 2: 2012—2017',
        url: '/files/ta-profiles/Site_Profile_CRT_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
    ],
  },
  {
    abbr: 'IN',
    name: 'Indiana',
    profiles: 1,
    files: [
      {
        title:
          'Partnership and Services to Increase Well-Being and Improve Permanency Outcomes for Children Affected by Substance Abuse in Indianapolis/Marion County - RPG Round 4: 2017—2022',
        url: '/files/ta-profiles/rpg-4-profile-in-voaohin.pdf',
        fileSize: '1%',
        status: 'past',
        round: '4',
      },
    ],
  },
  {
    abbr: 'OH',
    name: 'Ohio',
    profiles: 4,
    files: [
      {
        title:
          'Child Abuse and Neglect Substance Abuse Focus and Expansion (CANSAFE) - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Butler_County_OH_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title: 'Pre-Removal Family Drug Court Program - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/County_of_Lucas_OH_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title:
          'Enhancing Permanency in Children and Families (EPIC) Program - RPG Round 4: 2017—2022',
        url: '/files/ta-profiles/rpg-4-profile-oh-osu.pdf',
        fileSize: '1%',
        status: 'past',
        round: '4',
      },
      {
        title: 'Summit County Stars - Summit County Collaborative on Trauma, Alcohol & Other Drug & Resiliency Building Services for Children & Families (STARS) - RPG Round 2: 2012—2017',
        url: '/files/ta-profiles/Site_Profile_Summit_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
    ],
  },
  {
    abbr: 'PA',
    name: 'Pennsylvania',
    profiles: 3,
    files: [
      {
        title: 'The Young Child and Parent Project - RPG Round 5: 2018-2023',
        url: '/files/ta-profiles/rpg-5-profile_pa-hfp.pdf',
        fileSize: '1%',
        status: 'past',
        round: '5',
      },
      {
        title:
          'Young Child Parent Project-Two (YCPP2) - RPG Round 7: 2022—2027',
        url: '/files/ta-profiles/rpg-7-profile-pa-hfp.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
      {
        title:
          'The Philadelphia Partnership Family Recovery and Well-Being - RPG Round 2: 2012—2017',
        url: '/files/ta-profiles/Site_Profile_PA_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
    ],
  },
  {
    abbr: 'NJ',
    name: 'New Jersey',
    profiles: 1,
    files: [
      {
        title: 'Healing Hearts & Minds - RPG Round 6: 2019—2024',
        url: '/files/ta-profiles/rpg-6-profile-nj-acenda.pdf',
        fileSize: '1%',
        status: 'current',
        round: '6',
      },
    ],
  },
  {
    abbr: 'CT',
    name: 'Connecticut',
    profiles: 1,
    files: [
      {
        title:
          'Connecticut Strengthening Families Together (CT-SFT) - RPG Round 7: 2022—2027',
        url: '/files/ta-profiles/rpg-7-profile-ct-scdcf.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
    ],
  },
  {
    abbr: 'OR',
    name: 'Oregon',
    profiles: 5,
    files: [
      {
        title:
          'The Northeast Oregon Collaboration for Child Safety (NOCCS) - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/baker-county-or-rpg-1.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title: 'Klamath Tribes - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Klamath_Tribes_OR_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title: 'Multnomah County, Deartment of County Human Services, Mental Health and Addiction Services/Family Involvement Tream (FIT) - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Multnomah_County_OR_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title: 'OnTrack - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/OnTrack_Inc_OR_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title: 'Volunteers of America, Oregon/Miracles Family Recovery Program - RPG Round 3: 2014—2019',
        url: '/files/ta-profiles/Site_Profile_OR_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '3',
      },
    ],
  },
  {
    abbr: 'NV',
    name: 'Nevada',
    profiles: 2,
    files: [
      {
        title:
          'State of Nevada Division of Child and Family Services - RPG Round 2: 2012—2017',
        url: '/files/ta-profiles/Site_Profile_NV_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
      {
        title: 'Dependency Mothers Drug Court (DMDC) - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/State_of_Nevada_NV_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
    ],
  },
  {
    abbr: 'CO',
    name: 'Colorado',
    profiles: 4,
    files: [
      {
        title: 'Project Aware - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/AspenPointe_CO_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title:
          'Denver Entire Family Focused Effective Comprehensive Treatment (Denver EFFECT) - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Denver_Dept_of_Human_Services_CO_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title:
          'Northeastern Colorado Child Welfare Project (NRBH) - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/North_Range_Behavioral_Health_Center_CO_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title: 'Improving Well-Being, Permanency, and Safety for Children by Incorporating Peer Support Groups into the Innovative DANSR Approach - RPG Round 6: 2019—2024',
        url: '/files/ta-profiles/rpg-6-profile-co-judicial.pdf',
        fileSize: '1%',
        status: 'current',
        round: '6',
      },
    ],
  },
  {
    abbr: 'NE',
    name: 'Nebraska',
    profiles: 1,
    files: [
      {
        title:
          'Omaha Nation Community Response Team (ONCRT) Sacred Child Program (SCP) - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Omaha_ONCRT_NE_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
    ],
  },
  {
    abbr: 'MO',
    name: 'Missouri',
    profiles: 8,
    files: [
      {
        title: 'Kids Hope United-Hudelson Region - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/One_Hope_United_Hudelson_Region_MO_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title:
          'Preserving Families Through Partnerships in South Central Missouri - RPG Round 6: 2019—2024',
        url: '/files/ta-profiles/rpg-6-profile-mo-pfh.pdf',
        fileSize: '1%',
        status: 'current',
        round: '6',
      },
      {
        title:
          'Preserving Families Through Partnership - RPG Round 4: 2017—2022',
        url: '/files/ta-profiles/rpg-4-profile-mo-pfh.pdf',
        fileSize: '1%',
        status: 'past',
        round: '4',
      },
      {
        title:
          'Preserving Families Through Partnerships in Central Missouri (PFTP-CM) - RPG Round 5: 2018-2023',
        url: '/files/ta-profiles/rpg-5-profile-mo-pfh.pdf',
        fileSize: '1%',
        status: 'past',
        round: '5',
      },
      {
        title:
          'Regional Partnership Grant: Creating a Village (RPG: CAV) - RPG Round 7: 2022—2027',
        url: '/files/ta-profiles/rpg-7-profile-mo-cpr.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
      {
        title:
          'Preserving Families Through Partnerships (PFTP) - RPG Round 7: 2022—2027',
        url: '/files/ta-profiles/rpg-7-profile-mo-pfh.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
      {
        title:
          'SNAP Approach: Preserving Families Through Partnerships - RPG Round 2: 2012—2017',
        url: '/files/ta-profiles/Site_Profile_AO_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
      {
        title: 'Project Protect - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/St_Patrick_Center_MO_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
    ],
  },
  {
    abbr: 'KY',
    name: 'Kentucky',
    profiles: 5,
    files: [
      {
        title:
          'Families in Safe Homes Network (FISHN)/FISHN Initiative (FISHN-I) - RPG Round 1 Extension: 2012—2015',
        url: '/files/ta-profiles/Kentucky_LC_Complete_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1 Extension',
      },
      {
        title:
          'Kentucky Sobriety Treatment and Recovery Teams (K-Start for Marin County) - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/ky-dept-community-based-serv.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title: 'Communities of Hope - RPG Round 4: 2017—2022',
        url: '/files/ta-profiles/rpg-4-profile-ky-mccc.pdf',
        fileSize: '1%',
        status: 'past',
        round: '4',
      },
      {
        title:
          'Strengthening & Assisting Families in Eastern KY (SAFE) - RPG Round 7: 2022—2027',
        url: '/files/ta-profiles/rpg-7-profile-ky-mountain-comp.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
      {
        title:
          'Sobriety Treatment and Recovery Teams Daviess County - RPG Round 2: 2012—2017',
        url: '/files/ta-profiles/Site_Profile_KY_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
    ],
  },
  {
    abbr: 'WV',
    name: 'West Virgina',
    profiles: 3,
    files: [
      {
        title: 'West Virginia Regional Partnership-2 for Children and Families Impacted by Substance Abuse - Boone, Kanawha, Raleigh and Wyoming Counties - RPG Round 6: 2019—2024',
        url: '/files/ta-profiles/rpg-6-profile-wv-prestera.pdf',
        fileSize: '1%',
        status: 'current',
        round: '6',
      },
      {
        title:
          'West Virginia Regional Partnership (WVRP) for Children and Families Impacted by Substance Abuse - RPG Round 4: 2017—2022',
        url: '/files/ta-profiles/rpg_iv_grantee_profile_wv.pdf',
        fileSize: '1%',
        status: 'past',
        round: '4',
      },
      {
        title: "West Virginia's Regional Partnership 3 to Increase the Well-Being of, and to Improve the Permanency Outcomes for, Children and Families Affected by Opioids and Substance Abuse (WVRPG-3) - RPG Round 7: 2022—2027",
        url: '/files/ta-profiles/rpg-7-profile-wv-prestera.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
    ],
  },
  {
    abbr: 'VA',
    name: 'Virginia',
    profiles: 1,
    files: [
      {
        title:
          'Central Shenandoah Valley Family Collaboration: Substance Abuse Prevention and Treatment for At-Risk Families - RPG Round 2: 2012—2017',
        url: '/files/ta-profiles/Site_Profile_Rockingham_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
    ],
  },
  { abbr: 'MD', name: 'Maryland', profiles: 0, files: [] },
  {
    abbr: 'DE',
    name: 'Delaware',
    profiles: 1,
    files: [
      {
        title:
          'Delaware Healthy Outcomes with Parent Engagement (DE HOPE) - RPG Round 4: 2017—2022',
        url: '/files/ta-profiles/rpg-4-profile-de-cffde.pdf',
        fileSize: '1%',
        status: 'past',
        round: '4',
      },
    ],
  },
  {
    abbr: 'CA',
    name: 'California',
    profiles: 10,
    files: [
      {
        title:
          'The Northern California Regional Partnership for Safe and Stable Families (also known as the Butte County RPG) - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Butte_County_CA_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title: 'Family Link - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/CenterPoint_Inc_CA_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title:
          'Santa Clara County Family Wellness Court (FWC) - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/County_of_Santa_Clara_CA_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title: 'Treatment Alliance for Safe Children - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/County_of_Santa_Cruz_CA_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title: 'County of San Diego, Child Welfare Services - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/county-san-diego-hhs-agency.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title:
          'Mendocino County Family Dependency Drug Court - RPG Round 1 Extension: 2012—2015',
        url: '/files/ta-profiles/Mendocino_LC_Complete_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1 Extension',
      },
      {
        title:
          'Early Intervention Family Drug Court (EIFDC) - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/sacramento-dhhs-ca.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title: 'TAMAR Village Program - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/SHIELDS_CA_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title: 'FamilyLink - RPG Round 2: 2012—2017',
        url: '/files/ta-profiles/Site_Profile_CP_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
      {
        title: 'Screening, Making Decisions, Assessments, Referrals, Treatment (SMART-2) - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/WestCare_Inc_CA_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
    ],
  },
  { abbr: 'UT', name: 'Utah', profiles: 0, files: [] },
  {
    abbr: 'NM',
    name: 'New Mexico',
    profiles: 1,
    files: [
      {
        title: 'Clarity Counseling P.C. - RPG Round 1: 2007—2012',
        url: '',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
    ],
  },
  {
    abbr: 'KS',
    name: 'Kansas',
    profiles: 3,
    files: [
      {
        title:
          'Kansas Serves Substance Affected Families (KSSAF) Project - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Kansas_Department_of_Social_Rehab_KS_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title:
          'Kansas Serves Native American Families - KSNAF - RPG Round 4: 2017—2022',
        url: '/files/ta-profiles/rpg-4-profile-ks-ksnaf.pdf',
        fileSize: '1%',
        status: 'past',
        round: '4',
      },
      {
        title:
          'Kansas Serves Substance Affected Families (KSSAF) - RPG Round 3: 2014—2019',
        url: '/files/ta-profiles/Site_Profile_KS-university.pdf',
        fileSize: '1%',
        status: 'past',
        round: '3',
      },
    ],
  },
  { abbr: 'AR', name: 'Arkansas', profiles: 0, files: [] },
  {
    abbr: 'TN',
    name: 'Tennessee',
    profiles: 7,
    files: [
      {
        title: 'New Beginnings for Women and Children - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Child_and_Family_Tn_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title:
          'Regional Partnership for Families (RPF) - RPG Round 4: 2017—2022',
        url: '/files/ta-profiles/rpg-4-profile-tn-mcnabb.pdf',
        fileSize: '1%',
        status: 'past',
        round: '4',
      },
      {
        title:
          'McNabb Center’s Regional Partnership Project (RPP) - RPG Round 7: 2022—2027',
        url: '/files/ta-profiles/rpg-7-profile-tn-hrm.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
      {
        title:
          'Tennessee Department of Mental Health & Substance Abuse Services -HOMEBUILDERS - RPG Round 7: 2022—2027',
        url: '/files/ta-profiles/rpg-7-profile-tn-tdmhsas.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
      {
        title: 'New Beginnings for Children, Women and Families - RPG Round 2: 2012—2017',
        url: '/files/ta-profiles/Site_Profile_HRM_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
      {
        title: 'Therapeutic Intervention, Education, and Skills (TIES) - RPG Round 2: 2012—2017',
        url: '/files/ta-profiles/Site_Profile_TN_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
      {
        title:
          'Building Strong Families (BSF) in Rural Tennessee - RPG Round 1 Extension: 2012—2015',
        url: '/files/ta-profiles/Tenn_NH_Complete_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1 Extension',
      },
    ],
  },
  {
    abbr: 'NC',
    name: 'North Carolina',
    profiles: 1,
    files: [
      {
        title:
          'Robeson County Bridges for Families - RPG Round 1 Extension: 2012—2015',
        url: '/files/ta-profiles/NorthCarolina_JP_Complete_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1 Extension',
      },
    ],
  },
  { abbr: 'SC', name: 'South Carolina', profiles: 0, files: [] },
  { abbr: 'DC', name: 'District of Columbia', profiles: 0, files: [] },
  {
    abbr: 'AZ',
    name: 'Arizona',
    profiles: 1,
    files: [
      {
        title:
          'The Arizona Families F.I.R.S.T (AFF) Parent to Parent (P2P) Recovery Program - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/State_of_Arizona_AZ_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
    ],
  },
  {
    abbr: 'OK',
    name: 'Oklahoma',
    profiles: 6,
    files: [
      {
        title: 'Serving Our At-Risk (Project SOAR) - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Choctaw_Nation_OK_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title: 'Oklahoma Partnership Initiative - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/OK_DMH_and_SUS_OK_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title:
          'Substance Use Treatment and Access to Resources - RPG Round 6: 2019—2024',
        url: '/files/ta-profiles/rpg-6-profile-ok-odmhsas.pdf',
        fileSize: '1%',
        status: 'current',
        round: '6',
      },
      {
        title:
          'The Oklahoma Partnership Child Well-Being Initiative 3 (OPI-3) - RPG Round 4: 2017—2022',
        url: '/files/ta-profiles/rpg-4-profile-ok-odmhsas.pdf',
        fileSize: '1%',
        status: 'past',
        round: '4',
      },
      {
        title:
          'Collaborative Oklahoma Maternal Pathway to Access Substance Use Treatment Services (COMPASS) Initiative - RPG Round 7: 2022—2027',
        url: '/files/ta-profiles/rpg-7-profile-ok-odmhsas.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
      {
        title:
          'Oklahoma Partnership Initiative Phase-2 (OPI-2) - RPG Round 2: 2012—2017',
        url: '/files/ta-profiles/Site_Profile_OK_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
    ],
  },
  {
    abbr: 'LA',
    name: 'Louisiana',
    profiles: 1,
    files: [
      {
        title: 'Louisiana Regional Partnership Grant - RPG Round 7: 2022—2027',
        url: '/files/ta-profiles/rpg-7-profile-la-voasl.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
    ],
  },
  { abbr: 'MS', name: 'Mississippi', profiles: 0, files: [] },
  {
    abbr: 'AL',
    name: 'Alabama',
    profiles: 1,
    files: [
      {
        title:
          'UAB Comprehensive Addiction in Pregnancy Program (UAB CAPP) - RPG Round 4: 2017—2022',
        url: '/files/ta-profiles/rpg_iv_grantee_profile_al.pdf',
        fileSize: '1%',
        status: 'past',
        round: '4',
      },
    ],
  },
  {
    abbr: 'GA',
    name: 'Georgia',
    profiles: 4,
    files: [
      {
        title: 'Project Ready, Set, Go - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Juvenile_Justice_Fund_GA_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title:
          "Improving Outcomes of Georgia's Children and Families Affected by Substance Abuse: Strengthening the Partnership Between DFCS and FTCs - RPG Round 6: 2019—2024",
        url: '/files/ta-profiles/rpg-6-profile-ga-gsu.pdf',
        fileSize: '1%',
        status: 'current',
        round: '6',
      },
      {
        title: 'Georgia State University Research Foundation, Inc. Enhancing Safety and Well-Being of Children of Drug Court Participants - RPG Round 2: 2012—2017',
        url: '/files/ta-profiles/Site_Profile_GA_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '2',
      },
      {
        title:
          'Family Treatment Systems Collaborative; Appalachian and Doublas Family Depedency Treatment Courts (FDTCs) - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Supreme_Court_of_Georgia_GA_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
    ],
  },
  { abbr: 'HI', name: 'Hawaii', profiles: 0, files: [] },
  {
    abbr: 'TX',
    name: 'Texas',
    profiles: 3,
    files: [
      {
        title: 'Project Aware - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Aliviane_Inc_TX_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title: 'Safe4Kids - RPG Round 1: 2007—2012',
        url: '/files/ta-profiles/Houston_Council_TX_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title:
          'Parenting in Recovery (PIR)/PIRII - RPG Round 1 Extension: 2012—2015',
        url: '/files/ta-profiles/Travis_LC_Complete_508.pdf',
        fileSize: '301KB',
        status: 'past',
        round: '1 Extension',
      },
    ],
  },
  {
    abbr: 'FL',
    name: 'Florida',
    profiles: 6,
    files: [
      {
        title:
          "Children's Reunification Services Collaborative - RPG Round 1: 2007—2012",
        url: '/files/ta-profiles/Hillsborough_County_FL_508.pdf',
        fileSize: '1%',
        status: 'past',
        round: '1',
      },
      {
        title:
          'Family Connections Through Peer Recovery (Family-CPR) - RPG Round 4: 2017—2022',
        url: '/files/ta-profiles/rpg-4-profile-fl-bbhc.pdf',
        fileSize: '1%',
        status: 'past',
        round: '4',
      },
      {
        title:
          'Preserving Families and Protecting Children - RPG Round 5: 2018-2023',
        url: '/files/ta-profiles/rpg-5-profile_fl-fssnf.pdf',
        fileSize: '1%',
        status: 'past',
        round: '5',
      },
      {
        title:
          'Parent Partners for Families (PPFF) - RPG Round 5: 2018-2023',
        url: '/files/ta-profiles/rpg-5-profile-fl-citrus.pdf',
        fileSize: '1%',
        status: 'past',
        round: '5',
      },
      {
        title: 'Healthy Connections for Moms & Babies - RPG Round 7: 2022—2027',
        url: '/files/ta-profiles/rpg-7-profile-fl-broward.pdf',
        fileSize: '1%',
        status: 'current',
        round: '7',
      },
      {
        title: 'Miami-Dade Impact Project - RPG Round 3: 2014—2019',
        url: '/files/ta-profiles/Site_Profile_FL-Citrus.pdf',
        fileSize: '1%',
        status: 'past',
        round: '3',
      },
    ],
  },
  { abbr: 'PR', name: 'Puerto Rico', profiles: 0, files: [] },
];
export default usStateRPGData;
